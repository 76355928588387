import React, { useRef } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from 'components/SEO';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import linkResolver from 'core/linkResolver';
import { HeroHome } from 'components/HeroHome';
import { Artists } from 'components/Artists';
import { useIsomorphicLayoutEffect, usePrevious } from 'react-use';

const Home = ({ data, location, pageContext }) => {
  const artists = useRef(null);
  const anchor = location?.state?.anchor;
  const prevAnchor = usePrevious(anchor);

  useIsomorphicLayoutEffect(() => {
    if (artists.current && anchor && prevAnchor !== anchor) {
      requestAnimationFrame(() => artists.current.scrollIntoView());
    }
  }, [prevAnchor, anchor]);

  if (!data?.prismicHome?.data) return <h1>No data on home template</h1>;

  const { hero_title, hero_introduction, artists_title, artists_looping_text } =
    data.prismicHome.data;

  return (
    <Module>
      <SEO {...data.prismicHome.data} />
      <HeroHome title={hero_title?.text} richText={hero_introduction?.richText}></HeroHome>
      <Artists
        ref={artists}
        title={artists_title}
        loopText={artists_looping_text}
        artists={pageContext.siteData.artists}
      />
    </Module>
  );
};

const Module = styled.div``;

export default withPrismicPreview(Home, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
]);

export const pageQuery = graphql`
  query HomeQuery($uid: String!, $lang: String!) {
    prismicHome(uid: { eq: $uid }, lang: { eq: $lang }) {
      _previewable
      id
      uid
      data {
        seo_title
        seo_og_title
        seo_description
        seo_og_description
        title {
          text
        }
        hero_title {
          text
        }
        hero_introduction {
          richText
        }
        artists_title
        artists_looping_text
      }
    }
  }
`;
