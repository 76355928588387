import propsToDom from 'core/helpers/propsToDom';
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { h1, Title2 } from 'styles/typography';
import { asCol } from 'styles/utils';
import { ArtistTile } from './ArtistTile';
import { LoopText } from './LoopText';
import media from 'styles/media';

const ArtistsComponent = ({ title, loopText, artists, ...others }, ref) => {
  return (
    <div {...propsToDom(others)} ref={ref}>
      <Content>
        <Title2>{title}</Title2>
        <GridContainer>
          <Grid>
            {artists.map(({ uid, url, data }, index) => {
              return (
                <ArtistTile
                  key={`${uid}-${index}`}
                  url={url}
                  badge={data.thumbnail_badge}
                  image={data.list_thumbnail_image}
                  text={data.title?.text}
                />
              );
            })}
          </Grid>
        </GridContainer>
      </Content>
      <LoopTextTitle>{loopText}</LoopTextTitle>
    </div>
  );
};

const Content = styled.div`
  padding: ${asCol(1.25)} ${asCol(2)} 0;
`;

const GridContainer = styled.div`
  background: url('/images/background.jpg') ${({ theme }) => theme.colors.black};
  background-size: cover;
  padding: 4px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(10px, 1fr));
  background: ${({ theme }) => theme.colors.black};

  ${media.tabletPortrait`
    grid-template-columns: repeat(2, minmax(10px, 1fr));
  `}

  ${media.mobile`
    grid-template-columns: 1fr;
  `}
`;

const LoopTextTitle = styled(LoopText)`
  ${h1}
  padding: ${asCol(1.25)} 0;
`;

export const Artists = styled(forwardRef(ArtistsComponent))`
  position: relative;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};

  ${Title2} {
    margin-bottom: ${asCol(1.25)};
    text-align: center;
  }
`;
