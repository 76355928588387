import propsToDom from 'core/helpers/propsToDom';
import useParallax from 'hooks/useParallax';
import React from 'react';
import styled from 'styled-components';
import { Title1 } from 'styles/typography';
import { rem, asCol } from 'styles/utils';
import RichText from './RichText';
import { motion } from 'framer-motion';
import { clamp } from 'lodash';
import { useMeasure } from 'react-use';
import media from 'styles/media';

const HeroHomeComponent = ({ title, richText, ...others }) => {
  const [$fixed, { height }] = useMeasure();
  const [$content, yGlobal] = useParallax(
    (y, b) => {
      const max = b.h * 0.1;
      return clamp(y * 0.1, 0, max);
    },
    { animation: null, smooth: true }
  );
  const [$box, yBox] = useParallax((y, b) => (y / b.h) * 25, { animation: null });

  const isFixed = height && height > 0;

  return (
    <motion.div
      {...propsToDom(others)}
      style={{ height, y: yGlobal }}
      transformTemplate={({ y }) => `translateY(${y})`}
    >
      <Fixed ref={$fixed} isFixed={isFixed}>
        <Content ref={$content}>
          <Video
            src="/videos/background.mp4"
            poster="/videos/background.jpg"
            muted
            loop
            autoPlay
            playsInline
          />
          <Box transformTemplate={({ y }) => `translateY(${y})`}>
            <Title1>{title}</Title1>
            <BoxContent>
              <BoxBackground ref={$box} style={{ y: yBox }} />
              <RichText render={richText} />
            </BoxContent>
          </Box>
        </Content>
      </Fixed>
    </motion.div>
  );
};

const Fixed = styled.div`
  position: ${({ isFixed }) => (isFixed ? 'fixed' : null)};
  top: 0;
  left: 0;
  width: 100%;
`;

const Content = styled.div`
  padding: ${rem(180)} 0 ${rem(130)} 0;

  ${media.mobile`
    padding: ${rem(140)} 0 ${rem(52)} 0;
  `}
`;

const Video = styled(motion.video)`
  position: absolute;
  top: -15%;
  left: 0;
  height: 130%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
  will-change: transform;
`;

const Box = styled(motion.div)`
  box-sizing: border-box;
  width: ${asCol(22)};
  position: relative;
  z-index: 2;
  margin: 0 auto;
  text-align: center;

  ${Title1} {
    margin-left: ${asCol(-2)};
    margin-right: ${asCol(-2)};
    margin-bottom: -0.5em;
    position: relative;
    z-index: 3;
  }

  ${media.mobile`
    width: ${asCol(36)};
    text-align: left;

    ${Title1} {
      margin-left: ${asCol(0)};
      margin-right: ${asCol(0)};
    }
  `}
`;

const BoxContent = styled.div`
  position: relative;
  padding: ${asCol(1.25)} ${asCol(1)} ${asCol(1)};

  ${media.mobile`
    padding: ${asCol(4)};
  `}
`;

const BoxBackground = styled(motion.div)`
  position: absolute;
  inset: 0;
  background: ${({ theme }) => theme.colors.black};

  & ~ * {
    position: relative;
    z-index: 2;
  }
`;

export const HeroHome = styled(HeroHomeComponent)`
  position: relative;
  background: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  /* overflow: hidden; */
`;
