import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useMeasure } from 'react-use';
import { useGlobalContext } from 'store/GlobalProvider';
import { useInView } from 'react-intersection-observer';

const LoopTextComponent = ({
  children,
  isReversed = false,
  duration = 11,
  separator = null,
  ...others
}) => {
  const { ww } = useGlobalContext();
  const [clones, setClones] = useState(0);
  const [$element, { width }] = useMeasure();
  const [$main, isPlaying] = useInView({ threshold: 0 });

  useEffect(() => {
    if (ww > 0 && width > 0) {
      setClones(Math.ceil(ww / width) + 1);
    }
  }, [ww, width]);

  const props = {
    isReversed,
    isPlaying,
    duration,
  };

  return (
    <div ref={$main} {...others} key={`loop-text-${clones}`}>
      <Wrapper>
        <Item {...props} ref={$element}>
          {children}
          {separator}
        </Item>
        {new Array(clones).fill(0).map((_, i) => (
          <Item {...props} key={`clone-${i}`}>
            {children}
            {separator}
          </Item>
        ))}
      </Wrapper>
    </div>
  );
};

export const LoopText = styled(LoopTextComponent)`
  position: relative;
  overflow: hidden;
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

const Item = styled.div`
  @keyframes loop {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }

  display: inline-flex;
  flex-shrink: 0;
  animation-name: ${({ isReversed = false }) => (isReversed ? reverse : loop)};
  animation-duration: ${({ duration = 3 }) => `${duration}s`};
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-play-state: ${({ isPlaying }) => (isPlaying ? 'running' : 'paused')};
  will-change: transform;
`;

const loop = keyframes`
from {
  transform: translateX(0);
}
to {
  transform: translateX(-100%);
}
`;

const reverse = keyframes`
from {
  transform: translateX(-100%);
}
to {
  transform: translateX(0);
}
`;
